import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {Specialty} from "../specialties/specialty";
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  authenticate(username, password) {
    return this.httpClient.post<any>(environment.REST_API_URL + 'authenticate',{username,password}).pipe(
      map(
        userData => {
          sessionStorage.setItem('username',username);
          let tokenStr= 'Bearer '+userData.token;

          var decoded = jwt_decode(userData.token);
          sessionStorage.setItem('roles', decoded['roles'])
          sessionStorage.setItem('personId', decoded['personId']);
          sessionStorage.setItem('token', tokenStr);
          return userData;
        }
      )
    );
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem('username')
    return !(user === null)
  }

  isAdminUser() {
    let roles = sessionStorage.getItem('roles')
    return this.isUserLoggedIn() && roles !== null && roles.indexOf('ROLE_ADMIN') >= 0
  }

  isVetUser() {
    let roles = sessionStorage.getItem('roles')
    return this.isUserLoggedIn() && roles !== null && roles.indexOf('ROLE_VET') >= 0
  }

  getPersonId() {
    return sessionStorage.getItem('personId')
  }

  logOut() {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('roles');
    sessionStorage.removeItem('personId');
  }
}
