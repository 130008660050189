import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import {FormsModule} from "@angular/forms";
import {AuthenticationRoutingModule} from "./authentication-routing.module";
import {AuthenticationService} from "./authentication.service";
import {AuthenticationGuardService} from "./authentication-guard.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AuthenticationRoutingModule
  ],
  declarations: [
    LoginComponent,
    LogoutComponent],
  providers: [
    AuthenticationService,
    AuthenticationGuardService
  ]
})
export class AuthenticationModule { }
